import { Box, List } from '@mui/material';
import useLocales from 'src/hooks/useLocales';

import NavList from './NavList';
import { ListSubheaderStyle } from './style';
import { NavSectionProps } from './type';

export default function NavSection({ navConfig, isCollapse, ...other }: NavSectionProps) {
  const { t } = useLocales();
  return (
    <Box {...other}>
      {navConfig.map((group, index) => (
        <List key={`${index}-${group.subheader}`} disablePadding sx={{ px: 2 }}>
          {!!group.subheader && (
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
                // bgcolor: 'transparent',
              }}
            >
              {t(group.subheader)}
            </ListSubheaderStyle>
          )}

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
