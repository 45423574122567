const en = {
  common: {
    settings: 'Settings',
    version: 'Version {{version}}',
  },
  dialogs: {
    delete: {
      title: 'Deleting',
      warning:
        'Are you sure you want to delete this record? Please note that this action is irreversible, and there is no option to undo it.',
      warningWithDetails:
        'Are you sure you want to delete {{item}}? Please note that this action is irreversible, and there is no option to undo it.',
    },
  },
  table: {
    id: 'ID',
  },
  actions: {
    export: 'Export',
    save: 'Save',
    cancel: 'Cancel',
    create: 'Create',
    delete: 'Delete',
    logout: 'Logout',
  },
  settings: {
    mode: 'Mode',
    presets: 'Presets',
    fullscreen: 'Fullscreen',
    exitFullscreen: 'Exit Fullscreen',
  },
  navigation: {
    general: 'General',
    dashboard: 'Dashboard',
    content: 'Content',
    pages: 'Pages',
    posts: 'Posts',
    postCategories: 'Categories',
    accounts: 'Accounts',
    users: 'Users',
    permissions: 'Permissions',
    settings: 'Settings',
    viewer: 'Tree Viewer',
    ancestors: 'Ancestors',
    people: 'People',
    marriages: 'Marriages',
    familyTrees: 'Family Trees',
  },
  errors: {
    404: {
      title: '404 Page Not Found',
      header: 'Sorry, page not found!',
      body: "Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling.",
    },
  },
  //// NEW
  home: {
    title: 'Ancestor Family Tree',
    selectPerson: 'Person is not selected',
    details: {
      relations: 'Relations',
      events: 'Events',
      edit: 'Edit Details',
      addRelatives: 'Add Relatives',
      setRootPerson: 'Set As Root',
      deletePerson: 'Delete Person',
    },
    addPerson: {
      father: 'Add Father',
      mother: 'Add Mother',
      brother: 'Add Brother',
      sister: 'Add Sister',
      spouse: 'Add Spouse',
      child: 'Add Child',
    },
  },
  viewer: {
    treeSize: '{{total}} persons in tree, {{displayed}} displayed',
  },
  person: {
    form: {
      familyTree: 'Family Tree',
      showSiblings: 'Show Siblings',
      ancestorsDepth: 'Ancestors Depth',
      descendantsDepth: 'Descendants Depth',
      removalItemName: 'this profile image',
    },
    table: {
      name: 'Person',
      gender: 'Gender',
      birthdayPlace: 'Birth Place',
      birthdayDate: 'Birthday',
      deathDate: 'Date of Death',
    },
  },
};

export default en;
