// import SettingsDrawer from './drawer/SettingsDrawer';
import ThemeColorPresets from './ThemeColorPresets';

type Props = {
  children: React.ReactNode;
};

export default function ThemeSettings({ children }: Props) {
  return (
    <ThemeColorPresets>
      {children}
      {/*<SettingsDrawer />*/}
    </ThemeColorPresets>
  );
}
