import { Theme } from '@mui/material/styles';
import React from 'react';

import Iconify from '../../components/Iconify';

export default function TreeView(theme: Theme) {
  return {
    MuiTreeView: {
      defaultProps: {
        defaultCollapseIcon: (
          <Iconify icon={'ant-design:minus-square-outlined'} width={18} height={18} />
        ),
        defaultExpandIcon: (
          <Iconify icon={'ant-design:plus-square-outlined'} width={18} height={18} />
        ),
        defaultEndIcon: <Iconify icon={'akar-icons:circle'} width={18} height={8} />,
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          ...theme.typography.body1,
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
        },
        iconContainer: { width: 'auto' },
      },
    },
  };
}
