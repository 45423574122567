import { Id } from '../@types/id';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

function generateCRUDLinks(baseUri: string) {
  return {
    root: path(ROOTS_DASHBOARD, baseUri),
    new: path(ROOTS_DASHBOARD, baseUri + '/new'),
    view: (id: Id) => path(ROOTS_DASHBOARD, baseUri + `/${id}`),
    edit: (id: Id) => path(ROOTS_DASHBOARD, baseUri + `/${id}/edit`),
  };
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: generateCRUDLinks('users'),
  tree: generateCRUDLinks('trees'),
  person: generateCRUDLinks('records'),
  relation: generateCRUDLinks('relations'),
};
