import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import Scrollbar from './components/Scrollbar';
import ScrollToTop from './components/ScrollToTop';
import ThemeSettings from './components/settings/ThemeSettings';
import Router from './routes/Router';
import ThemeProvider from './theme/ThemeProvider';

const queryClient = new QueryClient();

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeSettings>
            <NotistackProvider>
              <Scrollbar disableHomepageScroll>
                <ProgressBarStyle />
                <ScrollToTop />
                <Router />
              </Scrollbar>
            </NotistackProvider>
          </ThemeSettings>
          {/*<ReactQueryDevtools initialIsOpen={false} />*/}
        </QueryClientProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
