import { Box, SxProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import SimpleBarReact, { Props as ScrollbarProps } from 'simplebar-react';

const RootStyle = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

const SimpleBarStyle = styled(SimpleBarReact)<{ variant?: 'small' | 'medium' }>(
  ({ theme, variant = 'medium' }) => ({
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
      '&:before': {
        backgroundColor: alpha(theme.palette.grey[600], 0.48),
      },
      '&.simplebar-visible:before': {
        opacity: 1,
      },
    },
    '& .simplebar-track.simplebar-vertical': {
      width: variant === 'small' ? 12 : 16,
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
      height: 10,
    },
    '& .simplebar-mask': {
      zIndex: 'inherit',
    },
  })
);

interface Props extends ScrollbarProps {
  sx?: SxProps;
  children?: ReactNode;
  variant?: 'small' | 'medium';
  disableHomepageScroll?: boolean;
}

export default function Scrollbar({
  children,
  sx,
  disableHomepageScroll = false,
  variant,
  ...other
}: Props) {
  const { pathname } = useLocation();

  const isHome = pathname === '/home';

  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return (
      <Box
        sx={{
          overflowX: 'auto',
          overflowY: disableHomepageScroll && isHome ? 'hidden' : 'visible',
          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle
        variant={variant}
        timeout={500}
        clickOnTrack={false}
        sx={{ overflowY: disableHomepageScroll && isHome ? 'hidden' : 'visible', ...sx }}
        {...other}
      >
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
}
