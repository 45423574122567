import { Theme } from '@mui/material/styles';

export default function Menu(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          marginInline: theme.spacing(1),
          marginBlock: theme.spacing(0.5),
          borderRadius: theme.shape.borderRadius,
          fontSize: theme.typography.body2,
          '&:first-of-type': {
            marginTop: 0,
          },
          '&:last-of-type': {
            marginBottom: 0,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    },
  };
}
