const uk = {
  common: {
    settings: 'Налаштування',
    version: 'Версія {{version}}',
    recordsNotFound: 'немає записів',
  },
  dialogs: {
    delete: {
      title: 'Видалення',
      warning:
        'Ви впевнені, що хочете видалити даний запис? Ця дія незворотна, і її неможливо скасувати.',
      warningWithDetails:
        'Ви впевнені, що хочете видалити {{item}}? Ця дія незворотна, і її неможливо скасувати.',
    },
  },
  table: {
    id: 'ID',
  },
  actions: {
    export: 'Експортувати',
    save: 'Зберегти',
    cancel: 'Скасувати',
    create: 'Створити',
    delete: 'Видалити',
    logout: 'Вийти',
  },
  navigation: {
    general: 'Загальні',
    dashboard: 'Панель Керування',
    content: 'Контент',
    pages: 'Сторінки',
    posts: 'Статті',
    postCategories: 'Категорії',
    accounts: 'Акаунти',
    users: 'Користувачі',
    permissions: 'Дозволи',
    settings: 'Налаштування',
    viewer: 'Перегляд',
    ancestors: 'Редагування',
    people: 'Особи',
    marriages: 'Стосунки',
    familyTrees: 'Дерева',
  },
  settings: {
    mode: 'Кольоровий Режим',
    presets: 'Основний Колір',
    fullscreen: 'Повноекранний Режим',
    exitFullscreen: 'Вийти з Повноекранного Режиму',
  },
  404: {
    title: '404 Сторінку Не Знайдено',
    header: 'Вибачте, сторінка не знайдена!',
    body: "На жаль, нам не вдалося знайти сторінку, яку ви шукаєте. Можливо, ви неправильно ввели URL? Обов'язково перевіряйте правопис.",
  },
  //// NEW
  home: {
    title: 'Родинне дерево',
    selectPerson: 'Виберіть особу',
    details: {
      relations: 'Стосунки',
      events: 'Події',
      edit: 'Редагувати дані',
      addRelatives: 'Додати родичів',
      setRootPerson: 'Переглянути дерево',
      deletePerson: 'Видалити особу',
    },
    addPerson: {
      father: 'Додати Батька',
      mother: 'Додати Матір',
      brother: 'Додати Брата',
      sister: 'Додати Сестру',
      spouse: 'Додати Партнера',
      child: 'Додати Дитину',
    },
  },
  viewer: {
    treeSize: '{{total}} осіб в дереві, {{displayed}} відображено',
  },
  person: {
    form: {
      familyTree: 'Родинне дерево',
      showSiblings: 'Близькі родичі',
      ancestorsDepth: 'Рівні відображення предків',
      descendantsDepth: 'Рівні відображення нащадків',
      removalItemName: 'дане зображення',
    },
    table: {
      name: 'Особа',
      gender: 'Стать',
      birthdayPlace: 'Місце Народження',
      birthdayDate: 'День народження',
      deathDate: 'Дата смерті',
    },
  },
  user: {
    dialog: {
      password: {
        title: 'Оновити Пароль',
      },
    },
  },
};

export default uk;
