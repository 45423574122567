import { createContext, ReactNode, useEffect, useState } from 'react';

import { MENU_COLLAPSE_STORAGE_KEY } from '../constants';
import { useLocalStorage } from '../hooks/useLocalStorage';
import useResponsive from '../hooks/useResponsive';

export type CollapseDrawerContextProps = {
  isCollapse: boolean;
  collapseClick: boolean;
  collapseHover: boolean;
  onToggleCollapse: VoidFunction;
  onHoverEnter: VoidFunction;
  onHoverLeave: VoidFunction;
};

const initialState: CollapseDrawerContextProps = {
  isCollapse: false,
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => {},
  onHoverEnter: () => {},
  onHoverLeave: () => {},
};

const CollapseDrawerContext = createContext(initialState);

type CollapseDrawerProviderProps = {
  children: ReactNode;
};

function CollapseDrawerProvider({ children }: CollapseDrawerProviderProps) {
  const isDesktop = useResponsive('up', 'lg');

  const [collapseClick, setCollapseClick] = useLocalStorage(MENU_COLLAPSE_STORAGE_KEY, false);

  const [collapseHover, setCollapseHover] = useState(false);

  useEffect(() => {
    if (!isDesktop) {
      setCollapseClick(false);
      setCollapseHover(false);
    }
  }, [isDesktop, setCollapseClick]);

  const handleToggleCollapse = () => {
    setCollapseClick((prev) => !prev);
  };

  const handleHoverEnter = () => {
    if (collapseClick) {
      setCollapseHover(true);
    }
  };

  const handleHoverLeave = () => {
    setCollapseHover(false);
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapseClick && !collapseHover,
        collapseClick: collapseClick,
        collapseHover: collapseHover,
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerContext, CollapseDrawerProvider };
