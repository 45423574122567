import { useTranslation } from 'react-i18next';

import { defaultLanguage, languages } from '../config';

export default function useLocales() {
  const { i18n, t } = useTranslation();

  const languageStorage = localStorage.getItem('i18nextLng');

  const currentLanguage =
    languages.find((language) => language.value === languageStorage) || defaultLanguage;

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return {
    t,
    setLanguage: handleChangeLanguage,
    currentLanguage,
    languages,
  };
}
