import { TextField, TextFieldProps } from '@mui/material';
import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type IProps = {
  name: string;
  children: ReactNode;
  emptyValue?: string;
};

export type RHFSelectProps = IProps & TextFieldProps;

export default function RHFSelect({ name, children, emptyValue = '', ...other }: RHFSelectProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          error={!!error}
          helperText={error?.message}
          value={field.value ?? emptyValue}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
