// import 'highlight.js/styles/stackoverflow-light.css';
// import 'highlight.js/styles/stackoverflow-dark.css.css';
import './highlight.css';

import hljs from 'highlight.js';

declare global {
  interface Window {
    hljs: typeof hljs;
  }
}

hljs.configure({
  languages: [
    'javascript',
    'typescript',
    'jsx',
    'php',
    'sh',
    'bash',
    'html',
    'scss',
    'css',
    'json',
  ],
});

if (typeof window !== 'undefined') {
  window.hljs = hljs;
}

export const highlightCodeblocks = (content: string) => {
  const doc = new DOMParser().parseFromString(content, 'text/html');

  doc.querySelectorAll<HTMLElement>('pre code').forEach((el) => {
    hljs.highlightElement(el);
  });

  const html = new XMLSerializer().serializeToString(doc.body);

  const match = [...html.matchAll(/<body [^>]+>((.|\s)*?)<\/body>/g)][0];

  return match && match[1];
};
