import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import useAuth from '../hooks/useAuth';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import Login from '../pages/auth/Login';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   ),
        // },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        // { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'new-password', element: <NewPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/home" replace />, index: true },
        { path: 'home', element: <Home /> },
        {
          path: 'trees',
          children: [
            { element: <TreeListPage />, index: true },
            { path: 'new', element: <CreateTreePage /> },
            { path: ':id/edit', element: <UpdateTreePage /> },
          ],
        },
        {
          path: 'records',
          children: [
            { element: <PersonListPage />, index: true },
            { path: 'new', element: <CreatePersonPage /> },
            { path: ':id/edit', element: <UpdatePersonPage /> },
          ],
        },
        {
          path: 'relations',
          children: [
            { element: <RelationListPage />, index: true },
            { path: 'new', element: <CreateRelationPage /> },
            { path: ':id/edit', element: <UpdateRelationPage /> },
          ],
        },
        {
          path: 'users',
          children: [
            { element: <UserListPage />, index: true },
            { path: 'new', element: <CreateUserPage /> },
            { path: ':id/edit', element: <UpdateUserPage /> },
            {
              path: 'permissions',
              children: [{ element: <ComingSoonPage />, index: true }],
              element: <ComingSoonPage />,
            },
          ],
        },
        { path: 'settings', element: <PageOne /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const Home = Loadable(lazy(() => import('../pages/home/Home')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const UserListPage = Loadable(lazy(() => import('../pages/user/UserListPage')));
const CreateUserPage = Loadable(lazy(() => import('../pages/user/CreateUserPage')));
const UpdateUserPage = Loadable(lazy(() => import('../pages/user/UpdateUserPage')));

const TreeListPage = Loadable(lazy(() => import('../pages/tree/TreeListPage')));
const CreateTreePage = Loadable(lazy(() => import('../pages/tree/CreateTreePage')));
const UpdateTreePage = Loadable(lazy(() => import('../pages/tree/UpdateTreePage')));

const PersonListPage = Loadable(lazy(() => import('../pages/person/PersonListPage')));
const CreatePersonPage = Loadable(lazy(() => import('../pages/person/CreatePersonPage')));
const UpdatePersonPage = Loadable(lazy(() => import('../pages/person/UpdatePersonPage')));

const RelationListPage = Loadable(lazy(() => import('../pages/relation/RelationListPage')));
const CreateRelationPage = Loadable(lazy(() => import('../pages/relation/CreateRelationPage')));
const UpdateRelationPage = Loadable(lazy(() => import('../pages/relation/UpdateRelationPage')));

const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
