import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

interface Props extends BoxProps {
  disabledLink?: boolean;
  showLabel?: boolean;
}

export default function Logo({ disabledLink = false, showLabel = false, sx }: Props) {
  const theme = useTheme();

  const PRIMARY = theme.palette.primary.main;

  const TEXT = theme.palette.text.secondary;

  const logo = (
    <Box sx={{ width: showLabel ? 200 : 40, height: 40, ...sx }}>
      <svg
        width="100%"
        height="100%"
        viewBox={showLabel ? '0 0 375 80' : '0 0 80 80'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <svg
          width="100%"
          height="100%"
          version="1.1"
          viewBox={showLabel ? '0 0 52.9 11.1' : '0 0 11.1 11.1'}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill={PRIMARY}>
            <path d="m5.26 2.52c-0.0743 0.0148-0.141 0.0591-0.185 0.133-0.564 0.86-0.8 1.63-0.8 2.82v2.06c-0.295-0.413-0.595-0.722-0.652-0.78l-1.19-1.28c-0.119-0.119-0.296-0.148-0.417-0.0295-0.119 0.119-0.149 0.296-0.03 0.417l1.19 1.3c0.297 0.297 1.1 1.25 1.1 1.87v1.54c0 0.178 0.119 0.297 0.297 0.297s0.296-0.119 0.296-0.297v-0.353c0-0.386 0.447-1.16 1.1-1.87l0.148-0.149c0.326-0.386 1.01-1.13 1.6-1.6 0.148-0.089 0.149-0.296 0.0595-0.417-0.089-0.148-0.296-0.149-0.417-0.0595-0.652 0.504-1.36 1.28-1.69 1.66l-0.119 0.149c-0.136 0.149-0.413 0.477-0.675 0.853-0.00169-0.00978-0.0038-0.0197-0.00568-0.0295v-3.29c0-1.07 0.208-1.72 0.712-2.49 0.089-0.148 0.0588-0.326-0.0894-0.417-0.0743-0.0444-0.155-0.0591-0.229-0.0444z" />
            <path d="m4.69 4.6c-0.089 0-0.178-0.0595-0.237-0.119-0.564-0.83-0.682-1.72-0.326-2.49 0.444-0.978 1.54-1.6 3.08-1.84 0.148-0.0297 0.267 0.0595 0.326 0.208 0.297 0.978 0.208 1.93-0.267 2.67-0.534 0.83-1.51 1.42-2.55 1.54 0 0.0297-0.0297 0.0297-0.0297 0.0297zm2.34-3.83c-1.22 0.208-2.05 0.712-2.37 1.45-0.237 0.534-0.178 1.16 0.178 1.75 0.622-0.119 1.45-0.504 1.93-1.25 0.356-0.564 0.444-1.22 0.267-1.96z" />
            <path d="m3.41 7.26c-2.7 0-3.26-1.42-3.26-3.56 0-0.148 0.119-0.297 0.297-0.297 1.13 0 1.93 0.208 2.46 0.743 0.564 0.564 0.8 1.39 0.8 2.82-3.36e-5 0.178-0.119 0.297-0.297 0.297zm-2.67-3.26c0.0297 1.69 0.474 2.58 2.37 2.67-0.0297-1.04-0.237-1.69-0.622-2.11-0.386-0.356-0.948-0.534-1.75-0.564z" />
            <path d="m5.77 8.43c-0.178 0-0.297-0.119-0.297-0.297 0-3.73 1.33-4.17 4.17-4.17 0.178 0 0.297 0.119 0.297 0.297 3.36e-5 3.73-1.33 4.17-4.17 4.17zm3.56-3.86c-2.34 0.0297-3.2 0.386-3.26 3.26 2.34-0.0296 3.2-0.386 3.26-3.26z" />
            <path d="m7.48 10.9h-5.78c-0.178 0-0.297-0.119-0.297-0.297s0.119-0.297 0.297-0.297h5.78c0.178 0 0.297 0.119 0.297 0.297s-0.119 0.297-0.297 0.297z" />
          </g>

          {showLabel && (
            <g fill={TEXT}>
              <path d="m16.6 8.38h-1.34v-0.66h-0.018q-0.463 0.773-1.37 0.773-0.669 0-1.06-0.377-0.382-0.382-0.382-1.02 0-1.34 1.59-1.55l1.25-0.166q0-0.755-0.817-0.755-0.822 0-1.56 0.49v-1.07q0.296-0.153 0.808-0.269 0.517-0.117 0.939-0.117 1.97 0 1.97 1.96zm-1.33-1.87v-0.31l-0.835 0.108q-0.692 0.0898-0.692 0.624 0 0.243 0.166 0.4 0.171 0.153 0.458 0.153 0.4 0 0.651-0.274 0.252-0.278 0.252-0.701z" />
              <path d="m22.2 8.38h-1.41v-2.56q0-1.07-0.764-1.07-0.368 0-0.606 0.283t-0.238 0.719v2.62h-1.42v-4.6h1.42v0.728h0.018q0.508-0.84 1.48-0.84 1.53 0 1.53 1.9z" />
              <path d="m26.8 8.21q-0.481 0.278-1.39 0.278-1.06 0-1.72-0.642-0.656-0.642-0.656-1.66 0-1.17 0.701-1.85 0.705-0.678 1.88-0.678 0.813 0 1.18 0.216v1.2q-0.445-0.332-0.993-0.332-0.611 0-0.97 0.359-0.355 0.355-0.355 0.984 0 0.611 0.341 0.961 0.341 0.346 0.939 0.346 0.53 0 1.04-0.332z" />
              <path d="m31.8 6.48h-3q0.0719 1 1.26 1 0.759 0 1.33-0.359v1.02q-0.638 0.341-1.66 0.341-1.11 0-1.73-0.615-0.615-0.62-0.615-1.72 0-1.15 0.665-1.81 0.665-0.669 1.63-0.669 1.01 0 1.55 0.597 0.552 0.597 0.552 1.62zm-1.32-0.871q0-0.988-0.8-0.988-0.341 0-0.593 0.283-0.247 0.283-0.301 0.705z" />
              <path d="m32.4 8.26v-1.15q0.35 0.211 0.696 0.314 0.35 0.103 0.66 0.103 0.377 0 0.593-0.103 0.22-0.103 0.22-0.314 0-0.135-0.0988-0.225-0.0988-0.0898-0.256-0.157-0.153-0.0674-0.337-0.121t-0.355-0.121q-0.274-0.103-0.485-0.22-0.207-0.121-0.35-0.278-0.139-0.157-0.216-0.364-0.0719-0.207-0.0719-0.49 0-0.386 0.166-0.665 0.171-0.278 0.449-0.454 0.283-0.18 0.642-0.261 0.364-0.0853 0.755-0.0853 0.305 0 0.62 0.0494 0.314 0.0449 0.62 0.135v1.1q-0.269-0.157-0.579-0.234-0.305-0.0808-0.602-0.0808-0.139 0-0.265 0.027-0.121 0.0225-0.216 0.0719-0.0943 0.0449-0.148 0.121-0.0539 0.0719-0.0539 0.166 0 0.126 0.0808 0.216 0.0808 0.0898 0.211 0.157 0.13 0.0629 0.287 0.117 0.162 0.0494 0.319 0.103 0.283 0.0988 0.512 0.216 0.229 0.117 0.391 0.274 0.166 0.157 0.252 0.368 0.0898 0.211 0.0898 0.503 0 0.409-0.18 0.701-0.175 0.287-0.472 0.472-0.292 0.18-0.678 0.261-0.382 0.0853-0.795 0.0853-0.759 0-1.41-0.234z" />
              <path d="m39.5 8.32q-0.314 0.166-0.948 0.166-1.5 0-1.5-1.56v-2.11h-0.746v-1.05h0.746v-0.993l1.41-0.404v1.4h1.03v1.05h-1.03v1.86q0 0.719 0.57 0.719 0.225 0 0.463-0.13z" />
              <path d="m42.5 8.49q-1.15 0-1.81-0.642-0.656-0.647-0.656-1.75 0-1.14 0.683-1.78 0.683-0.647 1.85-0.647 1.15 0 1.8 0.647 0.651 0.642 0.651 1.7 0 1.15-0.674 1.81-0.669 0.665-1.84 0.665zm0.0359-3.74q-0.503 0-0.782 0.346-0.278 0.346-0.278 0.979 0 1.33 1.07 1.33 1.02 0 1.02-1.36 0-1.29-1.03-1.29z" />
              <path d="m48.9 5.06q-0.256-0.139-0.597-0.139-0.463 0-0.723 0.341-0.261 0.337-0.261 0.921v2.2h-1.42v-4.6h1.42v0.853h0.018q0.337-0.934 1.21-0.934 0.225 0 0.35 0.0539z" />
              <path d="m49.3 8.26v-1.15q0.35 0.211 0.696 0.314 0.35 0.103 0.66 0.103 0.377 0 0.593-0.103 0.22-0.103 0.22-0.314 0-0.135-0.0988-0.225-0.0988-0.0898-0.256-0.157-0.153-0.0674-0.337-0.121-0.184-0.0539-0.355-0.121-0.274-0.103-0.485-0.22-0.207-0.121-0.35-0.278-0.139-0.157-0.216-0.364-0.0719-0.207-0.0719-0.49 0-0.386 0.166-0.665 0.171-0.278 0.449-0.454 0.283-0.18 0.642-0.261 0.364-0.0853 0.755-0.0853 0.305 0 0.62 0.0494 0.314 0.0449 0.62 0.135v1.1q-0.269-0.157-0.579-0.234-0.305-0.0808-0.602-0.0808-0.139 0-0.265 0.027-0.121 0.0225-0.216 0.0719-0.0943 0.0449-0.148 0.121-0.0539 0.0719-0.0539 0.166 0 0.126 0.0808 0.216 0.0808 0.0898 0.211 0.157 0.13 0.0629 0.287 0.117 0.162 0.0494 0.319 0.103 0.283 0.0988 0.512 0.216 0.229 0.117 0.391 0.274 0.166 0.157 0.252 0.368 0.0898 0.211 0.0898 0.503 0 0.409-0.18 0.701-0.175 0.287-0.472 0.472-0.292 0.18-0.678 0.261-0.382 0.0853-0.795 0.0853-0.759 0-1.41-0.234z" />
            </g>
          )}
        </svg>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
