import { CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';

import useSettings from '../../../hooks/useSettings';
import Iconify from '../../Iconify';

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  // height: 72,
  width: 38,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  // border: `2px solid ${theme.palette.grey[500_12]}`,
  borderRadius: 4,
  padding: 6,
}));

export default function ModeSwitch() {
  const { themeMode, onChangeMode } = useSettings();

  const changeMode = () => {
    onChangeMode(themeMode === 'dark' ? 'light' : 'dark');
  };

  return (
    <BoxStyle sx={{ color: 'primary.main' }} onClick={changeMode}>
      <Iconify
        icon={themeMode === 'dark' ? 'ph:sun-duotone' : 'ph:moon-duotone'}
        width={20}
        height={20}
      />
    </BoxStyle>
  );
}
