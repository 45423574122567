import Iconify from 'src/components/Iconify';

const navConfig = [
  {
    // subheader: 'navigation.general',
    items: [
      {
        title: 'navigation.viewer',
        path: '/home',
        icon: <Iconify icon={'ci:main-component'} />,
      },
    ],
  },
  {
    subheader: 'navigation.ancestors',
    items: [
      {
        title: 'navigation.people',
        path: '/records',
        icon: <Iconify icon={'fluent:leaf-two-16-filled'} />,
      },
      {
        title: 'navigation.marriages',
        path: '/relations',
        // icon: <Iconify icon={'icon-park-solid:circle-two-line'} />,
        icon: <Iconify icon={'icon-park-outline:oval-love-two'} />,
        // icon: <Iconify icon={'ic:outline-account-tree'} />,
      },
      {
        title: 'navigation.familyTrees',
        path: '/trees',
        icon: <Iconify icon={'ph:tree-fill'} />,
      },
    ],
  },
  {
    subheader: 'navigation.settings',
    items: [
      {
        title: 'navigation.accounts',
        path: '/users',
        icon: <Iconify icon={'mdi:accounts'} />,
      },
      // {
      //   title: 'navigation.users',
      //   path: 'users',
      //   icon: <Iconify icon={'mdi:accounts'} />,
      //   children: [
      //     { title: 'Accounts', path: '/users' },
      //     { title: 'navigation.permissions', path: '/users/permissions' },
      //   ],
      // },
      // {
      //   title: 'navigation.settings',
      //   path: '/settings',
      //   icon: <Iconify icon={'mdi:settings'} />,
      // },
    ],
  },
];

export default navConfig;
