import { Button, MenuItem } from '@mui/material';
import React, { MouseEvent, useState } from 'react';

import useLocales from '../../../hooks/useLocales';
import MenuPopover from '../../MenuPopover';

export default function LocaleSwitch() {
  const { setLanguage, languages, currentLanguage } = useLocales();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const onOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const close = () => {
    setOpen(null);
  };

  return (
    <>
      <Button sx={{ textTransform: 'uppercase' }} onClick={onOpen}>
        [{currentLanguage.value}]
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={close}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disabledArrow
        sx={{
          '& .MuiMenuItem-root': {
            marginInline: '0 !important',
            typography: 'body2',
            justifyContent: 'center',
          },
        }}
      >
        {languages.map((langauge) => (
          <MenuItem
            disabled={langauge.value === currentLanguage.value}
            key={langauge.value}
            onClick={() => {
              setLanguage(langauge.value);
              close();
            }}
          >
            {langauge.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
